import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';

// Find the container to hook the React app into.
const container = document.getElementById('root');

// Create a root.
const root = createRoot(container); // Create a root instance on the container.

// Initial render: Render the App component into the root.
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
