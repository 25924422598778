import React from 'react';
import { Link } from 'react-router-dom'; // Make sure you're using react-router-dom for navigation

function Nav() {
  return (
    <nav className="bg-primary text-white p-4 z-10 ">
      <div className="container mx-auto flex justify-between"> {/* Ensures full width and content spacing */}
        <div className="flex-initial"> {/* Logo or brand name can go here */}
          <Link to="/" className="text-white px-3 py-2 rounded-md text-sm font-medium">Brand</Link>
        </div>
        <div className="flex-initial"> {/* Right-aligned link items */}
          <ul className="flex justify-end">
            <li>
              <Link to="/" className="text-white px-3 py-2 rounded-md text-sm font-medium">Home</Link>
            </li>
            <li>
              <Link to="/about" className="text-white px-3 py-2 rounded-md text-sm font-medium">About</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
