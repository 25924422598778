//HomePage
//Imports
import React from 'react';


//Helper Functions



//JSX Return Functions

const returnJSX_video = () => {
    let youtube_video = 
    <div className="fixed inset-0 w-full h-full z-0">
      <video className="w-full h-full object-cover" autoPlay muted loop playsInline>
        <source src="./assets/testVideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
        
    return youtube_video;
}

const returnJSX_text = () => {
    let text = 
    <div className="absolute inset-0 flex items-center justify-center z-10">
      <div className="text-white text-center">
        <h1 className="text-4xl font-bold">Welcome to Abby's Music App</h1>
        <p className="text-lg">The best music app in the world</p>
      </div>
    </div>
        
    return text;
}



//Container to return the Home Page

export const HomePage = () => {
    const returnHomePage = () => {
        let homePage = 
        <div className='relative min-h-screen'>        
            
                {returnJSX_video()}
                {returnJSX_text()}
        </div>

        
    return homePage;
    }

        return <>{returnHomePage()}</>;
}
