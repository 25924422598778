import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Nav from './components/nav';
import { HomePage } from './components/Home';
import About from './components/About';
import Footer from './components/Footer';

// Layout component that decides whether to show Nav and Footer
const Layout = () => {
  const location = useLocation();
  const showNavAndFooter = location.pathname !== '/'; // Hide Nav and Footer on the home page

  return (
    <div className="flex flex-col min-h-screen">
      {showNavAndFooter && <Nav />}
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          {/* Additional routes can be added here */}
        </Routes>
      </div>
      {showNavAndFooter && <Footer />}
    </div>
  );
};

const App = () => {
  return <Layout />;
};

export default App;
